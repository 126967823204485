import * as React from "react";
import { useState, useCallback } from "react";
import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import AllMenu from "../../components/AllMenu";

const Projects = ({ data }) => {
  let projectList = data.allContentfulStudent.nodes;

  return (
    <Layout>
      <Seo title={`Kingston School of Art Foundation 2021 • Projects`} />
      <div className="allTop">
        <AllMenu />

        <div className="projectList">
          {projectList.map((project) => (
            <Link
              to={`/students/${project.slug}/`}
              key={project.slug}
              className="projectItem"
            >
              <div className="projectItemOverlay">{project.projectName}</div>

              {/* show an image if there is one */}
              {project.heroImage && (
                <GatsbyImage
                  objectFit="cover"
                  image={getImage(project.heroImage)}
                  alt={
                    project.heroImage.title
                      ? project.heroImage.title
                      : `Work of ${project.studentName}`
                  }
                />
              )}

              {/* //if there's no image show 'No image' */}
              {!project.heroImage && (
                <div style={{ textAlign: "center" }}>{project.studentName}</div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  query {
    allContentfulStudent(sort: { fields: projectName }) {
      nodes {
        slug
        studentName
        projectName
        projectDescription {
          projectDescription
        }
        heroImage {
          gatsbyImageData(width: 800, placeholder: BLURRED)
          id
          title
        }
      }
    }
  }
`;
